import { takeLatest, put, call } from 'redux-saga/effects';
import {
  BLOG_CATEGORY_ACTION,
  createBlogCategorySuccess,
  createBlogCategoryFailure,
  listBlogCategorySuccess,
  listBlogCategoryFailure,
  deleteBlogCategorySuccess,
  deleteBlogCategoryFailure,
  updateBlogCategoryFailure,
  updateBlogCategorySuccess,
} from './action';
import BLOG_CATEGORY_API from '../../api/blogCategory';

export default function* authSaga() {
  yield takeLatest(
    BLOG_CATEGORY_ACTION.CREATE_BLOG_CATEGORY_REQUEST,
    createBlogCategoryWatcher,
  );
  yield takeLatest(
    BLOG_CATEGORY_ACTION.LIST_BLOG_CATEGORY_REQUEST,
    listBlogCategoryWatcher,
  );
  yield takeLatest(
    BLOG_CATEGORY_ACTION.DELETE_BLOG_CATEGORY_REQUEST,
    deleteBlogCategoryWatcher,
  );
  yield takeLatest(
    BLOG_CATEGORY_ACTION.UPDATE_BLOG_CATEGORY_REQUEST,
    updateBlogCategoryWatcher,
  );
}

function* createBlogCategoryWatcher(action) {
  try {
    const data = action.payload;

    const response = yield call(BLOG_CATEGORY_API.createBlogCategory, data);
    const createBlogCategoryRes = response.data;

    yield put(createBlogCategorySuccess({ createBlogCategoryRes }));
  } catch (err) {
    const createBlogCategoryRes = err.response.data;
    yield put(createBlogCategoryFailure({ createBlogCategoryRes }));
  }
}

function* listBlogCategoryWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(BLOG_CATEGORY_API.listBlogCategory, data);
    const blogCategoryList = response.data.totalBlogCategoryList;
    yield put(listBlogCategorySuccess({ blogCategoryList }));
  } catch (err) {
    const listBlogCategroyRes = err.response.data;
    yield put(listBlogCategoryFailure({ listBlogCategroyRes }));
  }
}

function* deleteBlogCategoryWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(BLOG_CATEGORY_API.deleteBlogCategory, data);
    const deleteBlogCategoryRes = response.data;
    yield put(deleteBlogCategorySuccess({ deleteBlogCategoryRes }));
  } catch (err) {
    const deleteBlogCategoryRes = err.response.data;
    yield put(deleteBlogCategoryFailure({ deleteBlogCategoryRes }));
  }
}

function* updateBlogCategoryWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(BLOG_CATEGORY_API.updateBlogCategory, data);

    const updateBlogCategoryRes = response.data;
    yield put(updateBlogCategorySuccess({ updateBlogCategoryRes }));
  } catch (err) {
    const updateBlogCategoryRes = err.response.data;
    yield put(updateBlogCategoryFailure({ updateBlogCategoryRes }));
  }
}
