export const CAR_POST_ACTION = {
  GET_POST_LIST_REQUEST: 'GET_POST_LIST_REQUEST',
  GET_POST_LIST_SUCCESS: 'GET_POST_LIST_SUCCESS',
  GET_POST_LIST_FAILURE: 'GET_POST_LIST_FAILURE',

  GET_POST_DETAIL_REQUEST: 'GET_POST_DETAIL_REQUEST',
  GET_POST_DETAIL_SUCCESS: 'GET_POST_DETAIL_SUCCESS',
  GET_POST_DETAIL_FAILURE: 'GET_POST_DETAIL_FAILURE',

  BAN_POST_REQUEST: 'BAN_POST_REQUEST',
  BAN_POST_SUCCESS: 'BAN_POST_SUCCESS',
  BAN_POST_FAILURE: 'BAN_POST_FAILURE',

  DELETE_POST_REQUEST: 'DELETE_POST_REQUEST',
  DELETE_POST_SUCCESS: 'DELETE_POST_SUCCESS',
  DELETE_POST_FAILURE: 'DELETE_POST_FAILURE',

  REPORT_POST_REQUEST: 'REPORT_POST_REQUEST',
  REPORT_POST_SUCCESS: 'REPORT_POST_SUCCESS',
  REPORT_POST_FAILURE: 'REPORT_POST_FAILURE',
};

export const getPostListRequest = (data) => ({
  type: CAR_POST_ACTION.GET_POST_LIST_REQUEST,
  payload: data,
});
export const getPostListSuccess = ({ posts, totalPage, totalPosts }) => ({
  type: CAR_POST_ACTION.GET_POST_LIST_SUCCESS,
  payload: { posts, totalPage, totalPosts },
});
export const getPostListFailure = () => ({
  type: CAR_POST_ACTION.GET_POST_LIST_FAILURE,
});

export const getPostDetailRequest = (data) => ({
  type: CAR_POST_ACTION.GET_POST_DETAIL_REQUEST,
  payload: data,
});
export const getPostDetailSuccess = ({
  postDetail,
  sponsorPost,
  totalSpent,
}) => ({
  type: CAR_POST_ACTION.GET_POST_DETAIL_SUCCESS,
  payload: { postDetail, sponsorPost, totalSpent },
});
export const getPostDetailFailure = ({ postDetail }) => ({
  type: CAR_POST_ACTION.GET_POST_DETAIL_FAILURE,
  payload: { postDetail },
});

export const banPostRequest = (data) => ({
  type: CAR_POST_ACTION.BAN_POST_REQUEST,
  payload: data,
});
export const banPostSuccess = ({ banPostRes }) => ({
  type: CAR_POST_ACTION.BAN_POST_SUCCESS,
  payload: { banPostRes },
});
export const banPostFailure = ({ banPostRes }) => ({
  type: CAR_POST_ACTION.BAN_POST_FAILURE,
  payload: { banPostRes },
});

export const deletePostRequest = (data) => ({
  type: CAR_POST_ACTION.DELETE_POST_REQUEST,
  payload: data,
});
export const deletePostSuccess = ({ deletePostRes }) => ({
  type: CAR_POST_ACTION.DELETE_POST_SUCCESS,
  payload: { deletePostRes },
});
export const deletePostFailure = ({ deletePostRes }) => ({
  type: CAR_POST_ACTION.DELETE_POST_FAILURE,
  payload: { deletePostRes },
});

export const reportPostRequest = (data) => ({
  type: CAR_POST_ACTION.REPORT_POST_REQUEST,
  payload: data,
});
export const reportPostSuccess = ({ reportPostRes }) => ({
  type: CAR_POST_ACTION.REPORT_POST_SUCCESS,
  payload: { reportPostRes },
});
export const reportPostFailure = ({ reportPostRes }) => ({
  type: CAR_POST_ACTION.REPORT_POST_FAILURE,
  payload: { reportPostRes },
});
