import { all } from 'redux-saga/effects';

import authSaga from './authentication/saga';
import staffsSaga from './staffs/saga';
import companySaga from './company/saga';
import blogSaga from './blog/saga';
import customerSaga from './customer/saga';
import membershipSaga from './membership/saga';
import carPostSaga from './carPost/saga';
import sponsorSaga from './sponsor/saga';
import advertiseSaga from './advertise/saga';
import paymentSaga from './payment/saga';
import blogCategorySaga from './blogCategory/saga';

export default function* allSaga() {
  yield all([
    authSaga(),
    staffsSaga(),
    companySaga(),
    blogSaga(),
    blogCategorySaga(),
    customerSaga(),
    membershipSaga(),
    carPostSaga(),
    sponsorSaga(),
    advertiseSaga(),
    paymentSaga(),
  ]);
}
