export const CUSTOMER_ACTION = {
  GET_CUS_LIST_REQUEST: 'GET_CUS_LIST_REQUEST',
  GET_CUS_LIST_SUCCESS: 'GET_CUS_LIST_SUCCESS',
  GET_CUS_LIST_FAILURE: 'GET_CUS_LIST_FAILURE',

  GET_CUS_PAGES_REQUEST: 'GET_CUS_PAGES_REQUEST',
  GET_CUS_PAGES_SUCCESS: 'GET_CUS_PAGES_SUCCESS',
  GET_CUS_PAGES_FAILURE: 'GET_CUS_PAGES_FAILURE',

  UPDATE_MEMBERSHIP_REQUEST: 'UPDATE_MEMBERSHIP_REQUEST',
  UPDATE_MEMBERSHIP_SUCCESS: 'UPDATE_MEMBERSHIP_SUCCESS',
  UPDATE_MEMBERSHIP_FAILURE: 'UPDATE_MEMBERSHIP_FAILURE',
};

export const getCusListRequest = (data) => ({
  type: CUSTOMER_ACTION.GET_CUS_LIST_REQUEST,
  payload: data,
});
export const getCusListSuccess = ({ customers, totalCus, totalPage }) => ({
  type: CUSTOMER_ACTION.GET_CUS_LIST_SUCCESS,
  payload: { customers, totalCus, totalPage },
});
export const getCusListFailure = () => ({
  type: CUSTOMER_ACTION.GET_CUS_LIST_FAILURE,
});

export const getCusPagesRequest = (data) => ({
  type: CUSTOMER_ACTION.GET_CUS_PAGES_REQUEST,
  payload: data,
});
export const getCusPagesSuccess = ({ pages }) => ({
  type: CUSTOMER_ACTION.GET_CUS_PAGES_SUCCESS,
  payload: { pages },
});
export const getCusPagesFailure = () => ({
  type: CUSTOMER_ACTION.GET_CUS_PAGES_FAILURE,
});

export const updateMembershipRequest = (data) => ({
  type: CUSTOMER_ACTION.UPDATE_MEMBERSHIP_REQUEST,
  payload: data,
});
export const updateMembershipSuccess = ({ updateMembershipRes }) => ({
  type: CUSTOMER_ACTION.UPDATE_MEMBERSHIP_SUCCESS,
  payload: { updateMembershipRes },
});
export const updateMembershipFailure = ({ updateMembershipRes }) => ({
  type: CUSTOMER_ACTION.UPDATE_MEMBERSHIP_FAILURE,
  payload: { updateMembershipRes },
});
