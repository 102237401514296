import { ROUTE_API } from '../utils/route-util';
import HttpUtil from '../utils/http-util';

const company = {
  getCompanyInfo: async (data) =>
    await HttpUtil.get(ROUTE_API.updateCompanyInfo + '/' + data),

  updateCompanyInfo: async (data) =>
    await HttpUtil.post(ROUTE_API.updateCompanyInfo, data),
};

export default company;
