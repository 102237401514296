import { takeLatest, put, call } from 'redux-saga/effects';
import {
  MEMBERSHIP_ACTION,
  getMembershipListSuccess,
  getMembershipListFailure,
  getMembershipPlanSuccess,
  getMembershipPlanFailure,
  getMembershipSuccess,
  getMembershipFailure,
  updateMembershipTypeSuccess,
  updateMembershipTypeFailure,
} from './action';
import MEMBERSHIP_API from '../../api/membership';

export default function* staffsSaga() {
  yield takeLatest(
    MEMBERSHIP_ACTION.GET_MEMBERSHIP_LIST_REQUEST,
    getMembershipListRequestWatcher
  );
  yield takeLatest(
    MEMBERSHIP_ACTION.GET_MEMBERSHIP_PLAN_REQUEST,
    getMembershipPlanRequestWatcher
  );
  yield takeLatest(MEMBERSHIP_ACTION.GET_MEMBERSHIP_REQUEST, getMembershipRequestWatcher);
  yield takeLatest(
    MEMBERSHIP_ACTION.UPDATE_MEMBERSHIP_TYPE_REQUEST,
    updateMembershipTypeRequestWatcher
  );
}

function* getMembershipListRequestWatcher() {
  try {
    const response = yield call(MEMBERSHIP_API.getMembershipList);
    const membershipList = response.data.memberships;

    yield put(getMembershipListSuccess({ membershipList }));
  } catch (error) {
    yield put(getMembershipListFailure());
  }
}

function* getMembershipPlanRequestWatcher() {
  try {
    const response = yield call(MEMBERSHIP_API.getMembershipPlan);
    const membershipPlan = response.data.membershipPlan;

    yield put(getMembershipPlanSuccess({ membershipPlan }));
  } catch (error) {
    yield put(getMembershipPlanFailure());
  }
}

function* getMembershipRequestWatcher(action) {
  try {
    const id = action.payload;
    const response = yield call(MEMBERSHIP_API.getMembership, id);
    const membership = response.data.membership;

    yield put(getMembershipSuccess({ membership }));
  } catch (error) {
    yield put(getMembershipFailure());
  }
}

function* updateMembershipTypeRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(MEMBERSHIP_API.editMembership, data);
    const updateMembership = response.data;

    yield put(updateMembershipTypeSuccess({ updateMembership }));
  } catch (error) {
    const updateMembership = error.response.data;
    yield put(updateMembershipTypeFailure({ updateMembership }));
  }
}
