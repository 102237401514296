export const STAFFS_ACTION = {
  GET_ROLE_REQUEST: "GET_ROLE_REQUEST",
  GET_ROLE_SUCCESS: "GET_ROLE_SUCCESS",
  GET_ROLE_FAILURE: "GET_ROLE_FAILURE",

  STAFF_REQUEST: "STAFF_REQUEST",
  STAFF_SUCCESS: "STAFF_SUCCESS",
  STAFF_FAILURE: "STAFF_FAILURE",
};

export const getRoleRequest = () => ({
  type: STAFFS_ACTION.GET_ROLE_REQUEST,
});
export const getRoleSuccess = ({ roleList }) => ({
  type: STAFFS_ACTION.GET_ROLE_SUCCESS,
  payload: { roleList },
});
export const getRoleFailure = ({ roleList }) => ({
  type: STAFFS_ACTION.GET_ROLE_FAILURE,
  payload: { roleList },
});

export const staffRequest = (data) => ({
  type: STAFFS_ACTION.STAFF_REQUEST,
  payload: data,
});
export const staffSuccess = ({ staffData }) => ({
  type: STAFFS_ACTION.STAFF_SUCCESS,
  payload: { staffData },
});
export const staffFailure = ({ staffData }) => ({
  type: STAFFS_ACTION.STAFF_FAILURE,
  payload: staffData,
});
