export const SPONSOR_ACTION = {
  GET_SPONSOR_PACKAGE_REQUEST: 'GET_SPONSOR_PACKAGE_REQUEST',
  GET_SPONSOR_PACKAGE_SUCCESS: 'GET_SPONSOR_PACKAGE_SUCCESS',
  GET_SPONSOR_PACKAGE_FAILURE: 'GET_SPONSOR_PACKAGE_FAILURE',

  UPDATE_SPONSOR_PACKAGE_REQUEST: 'UPDATE_SPONSOR_PACKAGE_REQUEST',
  UPDATE_SPONSOR_PACKAGE_SUCCESS: 'UPDATE_SPONSOR_PACKAGE_SUCCESS',
  UPDATE_SPONSOR_PACKAGE_FAILURE: 'UPDATE_SPONSOR_PACKAGE_FAILURE',
};

export const getSponsorPackageRequest = () => ({
  type: SPONSOR_ACTION.GET_SPONSOR_PACKAGE_REQUEST,
});
export const getSponsorPackageSuccess = ({ sponsorPackage }) => ({
  type: SPONSOR_ACTION.GET_SPONSOR_PACKAGE_SUCCESS,
  payload: { sponsorPackage },
});
export const getSponsorPackageFailure = () => ({
  type: SPONSOR_ACTION.GET_SPONSOR_PACKAGE_FAILURE,
});

export const updateSponsorPackageRequest = ({ id, data }) => ({
  type: SPONSOR_ACTION.UPDATE_SPONSOR_PACKAGE_REQUEST,
  payload: { id, data },
});
export const updateSponsorPackageSuccess = ({ updateSponsorRes }) => ({
  type: SPONSOR_ACTION.UPDATE_SPONSOR_PACKAGE_SUCCESS,
  payload: { updateSponsorRes },
});
export const updateSponsorPackageFailure = ({ updateSponsorRes }) => ({
  type: SPONSOR_ACTION.UPDATE_SPONSOR_PACKAGE_FAILURE,
  payload: { updateSponsorRes },
});
