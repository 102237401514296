import { takeLatest, put, call } from 'redux-saga/effects';
import {
  CAR_POST_ACTION,
  getPostListSuccess,
  getPostListFailure,
  getPostDetailSuccess,
  getPostDetailFailure,
  banPostSuccess,
  banPostFailure,
  deletePostSuccess,
  deletePostFailure,
  reportPostSuccess,
  reportPostFailure
} from './action';
import CAR_POST_API from '../../api/carPost';

export default function* carPostSaga() {
  yield takeLatest(CAR_POST_ACTION.GET_POST_LIST_REQUEST, getPostListRequestWatcher);
  yield takeLatest(CAR_POST_ACTION.GET_POST_DETAIL_REQUEST, getPostDetailRequestWatcher);
  yield takeLatest(CAR_POST_ACTION.BAN_POST_REQUEST, banPostRequestWatcher);
  yield takeLatest(CAR_POST_ACTION.DELETE_POST_REQUEST, deletePostRequestWatcher);
  yield takeLatest(CAR_POST_ACTION.REPORT_POST_REQUEST, reportPostRequestWatcher);
}

function* getPostListRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(CAR_POST_API.getPostList, data);
    const posts = response.data.posts;
    const metadata = response.data.metadata;
    const totalPage = metadata.totalPage;
    const totalPosts = metadata.totalPosts;

    yield put(getPostListSuccess({ posts, totalPage, totalPosts }));
  } catch (error) {
    console.log(error);
    yield put(getPostListFailure());
  }
}

function* getPostDetailRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(CAR_POST_API.getPostDetail, data);
    const postDetail = response.data.post;
    const totalSpent = response.data.totalSpent;
    const sponsorPost = response.data.sponsorPost;

    yield put(getPostDetailSuccess({ postDetail, sponsorPost, totalSpent }));
  } catch (error) {
    const postDetail = error.response.data;
    yield put(getPostDetailFailure({ postDetail }));
  }
}

function* banPostRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(CAR_POST_API.banPost, data);
    const banPostRes = response.data;

    yield put(banPostSuccess({ banPostRes }));
  } catch (error) {
    const banPostRes = error.response.data;
    yield put(banPostFailure({ banPostRes }));
  }
}

function* deletePostRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(CAR_POST_API.deletePost, data);
    const deletePostRes = response.data;

    yield put(deletePostSuccess({ deletePostRes }));
  } catch (error) {
    const deletePostRes = error.response.data;
    yield put(deletePostFailure({ deletePostRes }));
  }
}

function* reportPostRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(CAR_POST_API.reportPost, data);
    const reportPostRes = response.data;

    yield put(reportPostSuccess({ reportPostRes }));
  } catch (error) {
    const reportPostRes = error.response.data;
    yield put(reportPostFailure({ reportPostRes }));
  }
}
