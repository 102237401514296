import { STAFFS_ACTION } from "./action";
import StateUtil from "../../utils/state-util";

const initialState = {
  isLoadingGetRole: false,
  isErrorGetRole: false,

  isLoadingStaff: false,
  isErrorStaff: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STAFFS_ACTION.GET_ROLE_REQUEST:
      return StateUtil.setRequest("GetRole", state);
    case STAFFS_ACTION.GET_ROLE_SUCCESS:
      return StateUtil.setSuccess("GetRole", {
        ...state,
        ...action.payload,
      });
    case STAFFS_ACTION.GET_ROLE_FAILURE:
      return StateUtil.setFailure("GetRole", {
        ...state,
        ...action.payload,
      });

    case STAFFS_ACTION.STAFF_REQUEST:
      return StateUtil.setRequest("Staff", {
        ...state,
        ...action.payload,
      });
    case STAFFS_ACTION.STAFF_SUCCESS:
      return StateUtil.setSuccess("Staff", {
        ...state,
        ...action.payload,
      });
    case STAFFS_ACTION.STAFF_FAILURE:
      return StateUtil.setFailure("Staff", {
        ...state,
        ...action.payload,
      });
    default:
      return state;
  }
};
