import { ROUTE_API } from '../utils/route-util';
import HttpUtil from '../utils/http-util';

const customer = {
  getCusList: async (data) => await HttpUtil.get(ROUTE_API.getCusList, { params: data }),

  getCusPages: async (postId) => {
    const response = await HttpUtil.get(
      ROUTE_API.getPostPage.replace(':id', postId)
    );
    return response;
  },

  updateMembership: async (data) => await HttpUtil.post(ROUTE_API.updateMembership, data),
};

export default customer;
