import { CUSTOMER_ACTION } from './action';
import StateUtil from '../../utils/state-util';

const initialState = {
  totalPage: 0,
  totalCus: 0,
  customers: [],
  isLoadingGetCusList: false,
  isErrorGetCusList: false,

  pages: [],
  isLoadingGetCusPages: false,
  isErrorGetCusPages: false,

  updateMembershipRes: {},
  isLoadingUpdateMembership: false,
  isErrorUpdateMembership: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_ACTION.GET_CUS_LIST_REQUEST:
      return StateUtil.setRequest('GetCusList', {
        ...state,
        ...action.payload,
      });
    case CUSTOMER_ACTION.GET_CUS_LIST_SUCCESS:
      return StateUtil.setSuccess('GetCusList', {
        ...state,
        ...action.payload,
      });
    case CUSTOMER_ACTION.GET_CUS_LIST_FAILURE:
      return StateUtil.setFailure('GetCusList', {
        ...state,
        ...action.payload,
      });

    case CUSTOMER_ACTION.GET_CUS_PAGES_REQUEST:
      return StateUtil.setRequest('GetCusPages', {
        ...state,
        ...action.payload,
      });
    case CUSTOMER_ACTION.GET_CUS_PAGES_SUCCESS:
      return StateUtil.setSuccess('GetCusPages', {
        ...state,
        ...action.payload,
      });
    case CUSTOMER_ACTION.GET_CUS_PAGES_FAILURE:
      return StateUtil.setFailure('GetCusPages', {
        ...state,
        ...action.payload,
      });

    case CUSTOMER_ACTION.UPDATE_MEMBERSHIP_REQUEST:
      return StateUtil.setRequest('UpdateMembership', {
        ...state,
        ...action.payload,
      });
    case CUSTOMER_ACTION.UPDATE_MEMBERSHIP_SUCCESS:
      return StateUtil.setSuccess('UpdateMembership', {
        ...state,
        ...action.payload,
      });
    case CUSTOMER_ACTION.UPDATE_MEMBERSHIP_FAILURE:
      return StateUtil.setFailure('UpdateMembership', {
        ...state,
        ...action.payload,
      });

    default:
      return state;
  }
};
