import { BLOG_ACTION } from './action';
import StateUtil from '../../utils/state-util';

const initialState = {
  createBlogRes: {},
  isLoadingCreateBlog: false,
  isErrorCreateBlog: false,

  blogList: {},
  isLoadingBlogList: false,
  isErrorBlogList: false,

  blogDetail: {},
  isLoadingBlogDetail: false,
  isErrorBlogDetail: false,

  updateBlogRes: {},
  isLoadingUpdateBlog: false,
  isErrorUpdateBlog: false,

  deleteBlogRes: {},
  isLoadingDeleteBlog: false,
  isErrorDeleteBlog: false,

  toggleStatusRes: {},
  isLoadingToggleStatus: false,
  isErrorToggleStatus: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BLOG_ACTION.CREATE_BLOG_REQUEST:
      return StateUtil.setRequest('CreateBlog', {
        ...state,
        ...action.payload,
      });
    case BLOG_ACTION.CREATE_BLOG_SUCCESS:
      return StateUtil.setSuccess('CreateBlog', {
        ...state,
        ...action.payload,
      });
    case BLOG_ACTION.CREATE_BLOG_FAILURE:
      return StateUtil.setFailure('CreateBlog', {
        ...state,
        ...action.payload,
      });

    case BLOG_ACTION.LIST_BLOG_REQUEST:
      return StateUtil.setRequest('BlogList', {
        ...state,
        ...action.payload,
      });
    case BLOG_ACTION.LIST_BLOG_SUCCESS:
      return StateUtil.setSuccess('BlogList', {
        ...state,
        ...action.payload,
      });
    case BLOG_ACTION.LIST_BLOG_FAILURE:
      return StateUtil.setFailure('BlogList', {
        ...state,
        ...action.payload,
      });

    case BLOG_ACTION.GET_BLOG_DETAIL_REQUEST:
      return StateUtil.setRequest('BlogDetail', {
        ...state,
        ...action.payload,
      });
    case BLOG_ACTION.GET_BLOG_DETAIL_SUCCESS:
      return StateUtil.setSuccess('BlogDetail', {
        ...state,
        ...action.payload,
      });
    case BLOG_ACTION.GET_BLOG_DETAIL_FAILURE:
      return StateUtil.setFailure('BlogDetail', {
        ...state,
        ...action.payload,
      });

    case BLOG_ACTION.UPDATE_BLOG_REQUEST:
      return StateUtil.setRequest('UpdateBlog', {
        ...state,
        ...action.payload,
      });
    case BLOG_ACTION.UPDATE_BLOG_SUCCESS:
      return StateUtil.setSuccess('UpdateBlog', {
        ...state,
        ...action.payload,
      });
    case BLOG_ACTION.UPDATE_BLOG_FAILURE:
      return StateUtil.setFailure('UpdateBlog', {
        ...state,
        ...action.payload,
      });

    case BLOG_ACTION.DELETE_BLOG_REQUEST:
      return StateUtil.setRequest('DeleteBlog', {
        ...state,
        ...action.payload,
      });
    case BLOG_ACTION.DELETE_BLOG_SUCCESS:
      return StateUtil.setSuccess('DeleteBlog', {
        ...state,
        ...action.payload,
      });
    case BLOG_ACTION.DELETE_BLOG_FAILURE:
      return StateUtil.setFailure('DeleteBlog', {
        ...state,
        ...action.payload,
      });

    case BLOG_ACTION.TOGGLE_STATUS_REQUEST:
      return StateUtil.setRequest('ToggleStatus', {
        ...state,
        ...action.payload,
      });
    case BLOG_ACTION.TOGGLE_STATUS_SUCCESS:
      return StateUtil.setSuccess('ToggleStatus', {
        ...state,
        ...action.payload,
      });
    case BLOG_ACTION.TOGGLE_STATUS_FAILURE:
      return StateUtil.setFailure('ToggleStatus', {
        ...state,
        ...action.payload,
      });

    default:
      return state;
  }
};
