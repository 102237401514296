import axios from 'axios';
import { ROUTE_API } from './route-util';

const HttpUtil = axios.create({
  baseURL: ROUTE_API.root,
});

HttpUtil.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

HttpUtil.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // console.log(error.response);
    if (error.response?.status === 401 && !error.response?.data?.message) {
      const token = localStorage.getItem('token');
      if (token) {
        localStorage.clear();
        alert('Sorry, your token expired. Please login again.');
        window.location.reload();
      }
    }
    return Promise.reject(error);
  },
);

export default HttpUtil;
