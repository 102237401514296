import { CAR_POST_ACTION } from './action';
import StateUtil from '../../utils/state-util';

const initialState = {
  posts: [],
  totalPage: 0,
  totalPosts: 0,
  isLoadingGetPostList: false,
  isErrorGetPostList: false,

  postDetail: {},
  totalSpent: 0,
  sponsorPost: {},
  isLoadingPostDetail: false,
  isErrorPostDetail: false,

  banPostRes: {},
  isLoadingBanPost: false,
  isErrorBanPost: false,

  deletePostRes: {},
  isLoadingDeletePost: false,
  isErrorDeletePost: false,

  reportPostRes: {},
  isLoadingReportPost: false,
  isErrorReportPost: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CAR_POST_ACTION.GET_POST_LIST_REQUEST:
      return StateUtil.setRequest('GetPostList', {
        ...state,
        ...action.payload,
      });
    case CAR_POST_ACTION.GET_POST_LIST_SUCCESS:
      return StateUtil.setSuccess('GetPostList', {
        ...state,
        ...action.payload,
      });
    case CAR_POST_ACTION.GET_POST_LIST_FAILURE:
      return StateUtil.setFailure('GetPostList', {
        ...state,
        ...action.payload,
      });

    case CAR_POST_ACTION.GET_POST_DETAIL_REQUEST:
      return StateUtil.setRequest('PostDetail', {
        ...state,
        ...action.payload,
      });
    case CAR_POST_ACTION.GET_POST_DETAIL_SUCCESS:
      return StateUtil.setSuccess('PostDetail', {
        ...state,
        ...action.payload,
      });
    case CAR_POST_ACTION.GET_POST_DETAIL_FAILURE:
      return StateUtil.setFailure('PostDetail', {
        ...state,
        ...action.payload,
      });

    case CAR_POST_ACTION.BAN_POST_REQUEST:
      return StateUtil.setRequest('BanPost', {
        ...state,
        ...action.payload,
      });
    case CAR_POST_ACTION.BAN_POST_SUCCESS:
      return StateUtil.setSuccess('BanPost', {
        ...state,
        ...action.payload,
      });
    case CAR_POST_ACTION.BAN_POST_FAILURE:
      return StateUtil.setFailure('BanPost', {
        ...state,
        ...action.payload,
      });

    case CAR_POST_ACTION.DELETE_POST_REQUEST:
      return StateUtil.setRequest('DeletePost', {
        ...state,
        ...action.payload,
      });
    case CAR_POST_ACTION.DELETE_POST_SUCCESS:
      return StateUtil.setSuccess('DeletePost', {
        ...state,
        ...action.payload,
      });
    case CAR_POST_ACTION.DELETE_POST_FAILURE:
      return StateUtil.setFailure('DeletePost', {
        ...state,
        ...action.payload,
      });

    case CAR_POST_ACTION.REPORT_POST_REQUEST:
      return StateUtil.setRequest('ReportPost', {
        ...state,
        ...action.payload,
      });
    case CAR_POST_ACTION.REPORT_POST_SUCCESS:
      return StateUtil.setSuccess('ReportPost', {
        ...state,
        ...action.payload,
      });
    case CAR_POST_ACTION.REPORT_POST_FAILURE:
      return StateUtil.setFailure('ReportPost', {
        ...state,
        ...action.payload,
      });
    default:
      return state;
  }
};
