import { PAYMENT_ACTION } from './action';
import StateUtil from '../../utils/state-util';

const initialState = {
  paymentList: [],
  totalPage: 0,
  totalPayments: 0,
  isLoadingGetPaymentList: false,
  isErrorGetPaymentList: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PAYMENT_ACTION.GET_PAYMENT_LIST_REQUEST:
      return StateUtil.setRequest('GetPaymentList', {
        ...state,
        ...action.payload,
      });
    case PAYMENT_ACTION.GET_PAYMENT_LIST_SUCCESS:
      return StateUtil.setSuccess('GetPaymentList', {
        ...state,
        ...action.payload,
      });
    case PAYMENT_ACTION.GET_PAYMENT_LIST_FAILURE:
      return StateUtil.setFailure('GetPaymentList', state);

    default:
      return state;
  }
};
