import { ROUTE_API } from '../utils/route-util';
import HttpUtil from '../utils/http-util';

const blogCategory = {
  createBlogCategory: async (data) =>
    await HttpUtil.post(ROUTE_API.createBlogCategory, data),
  listBlogCategory: async (data) =>
    await HttpUtil.get(ROUTE_API.blogCategoryList, {
      params: { ...data },
    }),
  deleteBlogCategory: async (data) =>
    await HttpUtil.delete(ROUTE_API.deleteBlogCategory, {
      params: {
        ...data,
      },
    }),
  updateBlogCategory: async (data) =>
    await HttpUtil.put(ROUTE_API.updateBlogCategory + '/' + data.id, data.data),
};

export default blogCategory;
