import jwt from 'jsonwebtoken';

const useAuth = () => {
  const token = localStorage.getItem('token');
  const decodedToken = jwt.decode(token);
  const dateNow = Date.now() / 1000;

  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  let userRole = '';

  if (userInfo?.role) {
    userRole = userInfo.role?.slug;
  }

  // console.log('userInfo:', userInfo);
  // console.log('userRole:', userRole);

  const isAuth = () => {
    if (token) {
      if (decodedToken?.exp > dateNow) return true;
    }
    localStorage.clear();
    return false;
  };

  return {
    isAuth,
    decodedToken,
    userInfo,
    userRole,
  };
};

export default useAuth;
