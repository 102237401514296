import React from 'react';

import { Provider } from 'react-redux';
import { store } from './redux/store';

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';

import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './routes';

import theme from './themes';
import './App.css';

function App() {
  return (
    <Router>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Provider store={store}>
          <Routes />
        </Provider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
