import { SPONSOR_ACTION } from './action';
import StateUtil from '../../utils/state-util';

const initialState = {
  sponsorPackage: [],
  isLoadingGetSponsor: false,
  isErrorGetSponsor: false,

  updateSponsorRes: {},
  isLoadingUpdateSponsor: false,
  isErrorUpdateSponsor: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SPONSOR_ACTION.GET_SPONSOR_PACKAGE_REQUEST:
      return StateUtil.setRequest('GetSponsor', state);
    case SPONSOR_ACTION.GET_SPONSOR_PACKAGE_SUCCESS:
      return StateUtil.setSuccess('GetSponsor', {
        ...state,
        ...action.payload,
      });
    case SPONSOR_ACTION.GET_SPONSOR_PACKAGE_FAILURE:
      return StateUtil.setFailure('GetSponsor', {
        ...state,
        ...action.payload,
      });

    case SPONSOR_ACTION.UPDATE_SPONSOR_PACKAGE_REQUEST:
      return StateUtil.setRequest('UpdateSponsor', {
        ...state,
        ...action.payload,
      });
    case SPONSOR_ACTION.UPDATE_SPONSOR_PACKAGE_SUCCESS:
      return StateUtil.setSuccess('UpdateSponsor', {
        ...state,
        ...action.payload,
      });
    case SPONSOR_ACTION.UPDATE_SPONSOR_PACKAGE_FAILURE:
      return StateUtil.setFailure('UpdateSponsor', {
        ...state,
        ...action.payload,
      });

    default:
      return state;
  }
};
