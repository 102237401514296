import { applyMiddleware, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';

import allReducer from './all-reducer';
import allSaga from './all-saga';

const sagaMiddleware = createSagaMiddleware();

const store = createStore(allReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(allSaga);

export { store };
