export const COMPANY_ACTION = {
  GET_COMPANY_INFO_REQUEST: 'GET_COMPANY_INFO_REQUEST',
  GET_COMPANY_INFO_SUCCESS: 'GET_COMPANY_INFO_SUCCESS',
  GET_COMPANY_INFO_FAILURE: 'GET_COMPANY_INFO_FAILURE',

  UPDATE_COMPANY_INFO_REQUEST: 'UPDATE_COMPANY_INFO_REQUEST',
  UPDATE_COMPANY_INFO_SUCCESS: 'UPDATE_COMPANY_INFO_SUCCESS',
  UPDATE_COMPANY_INFO_FAILURE: 'UPDATE_COMPANY_INFO_FAILURE',
};

export const getCompnayInfoRequest = (data) => ({
  type: COMPANY_ACTION.GET_COMPANY_INFO_REQUEST,
  payload: data,
});
export const getCompnayInfoSuccess = ({ companyInfo }) => ({
  type: COMPANY_ACTION.GET_COMPANY_INFO_SUCCESS,
  payload: { companyInfo },
});
export const getCompnayInfoFailure = ({ companyInfo }) => ({
  type: COMPANY_ACTION.GET_COMPANY_INFO_FAILURE,
  payload: { companyInfo },
});

export const updateCompnayInfoRequest = (data) => ({
  type: COMPANY_ACTION.UPDATE_COMPANY_INFO_REQUEST,
  payload: data,
});
export const updateCompnayInfoSuccess = ({ updateCompanyInfoRes }) => ({
  type: COMPANY_ACTION.UPDATE_COMPANY_INFO_SUCCESS,
  payload: { updateCompanyInfoRes },
});
export const updateCompnayInfoFailure = ({ updateCompanyInfoRes }) => ({
  type: COMPANY_ACTION.UPDATE_COMPANY_INFO_FAILURE,
  payload: { updateCompanyInfoRes },
});
