export const BLOG_CATEGORY_ACTION = {
  CREATE_BLOG_CATEGORY_REQUEST: 'CREATE_BLOG_CATEGORY_REQUEST',
  CREATE_BLOG_CATEGORY_SUCCESS: 'CREATE_BLOG_CATEGORY_SUCCESS',
  CREATE_BLOG_CATEGORY_FAILURE: 'CREATE_BLOG_CATEGORY_FAILURE',

  LIST_BLOG_CATEGORY_REQUEST: 'LIST_BLOG_CATEGORY_REQUEST',
  LIST_BLOG_CATEGORY_SUCCESS: 'LIST_BLOG_CATEGORY_SUCCESS',
  LIST_BLOG_CATEGORY_FAILURE: 'LIST_BLOG_CATEGORY_FAILURE',

  DELETE_BLOG_CATEGORY_REQUEST: 'DELETE_BLOG_CATEGORY_REQUEST',
  DELETE_BLOG_CATEGORY_SUCCESS: 'DELETE_BLOG_CATEGORY_SUCCESS',
  DELETE_BLOG_CATEGORY_FAILURE: 'DELETE_BLOG_CATEGORY_FAILURE',

  UPDATE_BLOG_CATEGORY_REQUEST: 'UPDATE_BLOG_CATEGORY_REQUEST',
  UPDATE_BLOG_CATEGORY_SUCCESS: 'UPDATE_BLOG_CATEGORY_SUCCESS',
  UPDATE_BLOG_CATEGORY_FAILURE: 'UPDATE_BLOG_CATEGORY_FAILURE',
};

export const createBlogCategoryRequest = (data) => ({
  type: BLOG_CATEGORY_ACTION.CREATE_BLOG_CATEGORY_REQUEST,
  payload: data,
});

export const createBlogCategorySuccess = ({ createBlogCategoryRes }) => ({
  type: BLOG_CATEGORY_ACTION.CREATE_BLOG_CATEGORY_SUCCESS,
  payload: { createBlogCategoryRes },
});

export const createBlogCategoryFailure = ({ createBlogCategoryRes }) => ({
  type: BLOG_CATEGORY_ACTION.CREATE_BLOG_CATEGORY_FAILURE,
  payload: { createBlogCategoryRes },
});

export const listBlogCategoryRequest = (data) => ({
  type: BLOG_CATEGORY_ACTION.LIST_BLOG_CATEGORY_REQUEST,
  payload: data,
});

export const listBlogCategorySuccess = ({ blogCategoryList }) => ({
  type: BLOG_CATEGORY_ACTION.LIST_BLOG_CATEGORY_SUCCESS,
  payload: { blogCategoryList },
});

export const listBlogCategoryFailure = ({ blogCategoryList }) => ({
  type: BLOG_CATEGORY_ACTION.LIST_BLOG_CATEGORY_FAILURE,
  payload: { blogCategoryList },
});

export const deleteBlogCategoryRequest = (data) => ({
  type: BLOG_CATEGORY_ACTION.DELETE_BLOG_CATEGORY_REQUEST,
  payload: data,
});

export const deleteBlogCategorySuccess = ({ deleteBlogCategoryRes }) => ({
  type: BLOG_CATEGORY_ACTION.DELETE_BLOG_CATEGORY_SUCCESS,
  payload: { deleteBlogCategoryRes },
});

export const deleteBlogCategoryFailure = ({ deleteBlogCategoryRes }) => ({
  type: BLOG_CATEGORY_ACTION.DELETE_BLOG_CATEGORY_FAILURE,
  payload: { deleteBlogCategoryRes },
});

export const updateBlogCategoryRequest = (data) => ({
  type: BLOG_CATEGORY_ACTION.UPDATE_BLOG_CATEGORY_REQUEST,
  payload: data,
});

export const updateBlogCategorySuccess = ({ updateBlogCategoryRes }) => ({
  type: BLOG_CATEGORY_ACTION.UPDATE_BLOG_CATEGORY_SUCCESS,
  payload: { updateBlogCategoryRes },
});

export const updateBlogCategoryFailure = ({ updateBlogCategoryRes }) => ({
  type: BLOG_CATEGORY_ACTION.UPDATE_BLOG_CATEGORY_FAILURE,
  payload: { updateBlogCategoryRes },
});
