import { COMPANY_ACTION } from './action';
import StateUtil from '../../utils/state-util';

const initialState = {
  companyInfo: {},
  isLoadingGetCompanyInfo: false,
  isErrorGetCompanyInfo: false,

  updateCompanyInfoRes: {},
  isLoadingUpdateCompanyInfo: false,
  isErrorUpdateCompanyInfo: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case COMPANY_ACTION.GET_COMPANY_INFO_REQUEST:
      return StateUtil.setRequest('GetCompanyInfo', {
        ...state,
        ...action.payload,
      });
    case COMPANY_ACTION.GET_COMPANY_INFO_SUCCESS:
      return StateUtil.setSuccess('GetCompanyInfo', {
        ...state,
        ...action.payload,
      });
    case COMPANY_ACTION.GET_COMPANY_INFO_FAILURE:
      return StateUtil.setFailure('GetCompanyInfo', {
        ...state,
        ...action.payload,
      });

    case COMPANY_ACTION.UPDATE_COMPANY_INFO_REQUEST:
      return StateUtil.setRequest('UpdateCompanyInfo', {
        ...state,
        ...action.payload,
      });
    case COMPANY_ACTION.UPDATE_COMPANY_INFO_SUCCESS:
      return StateUtil.setSuccess('UpdateCompanyInfo', {
        ...state,
        ...action.payload,
      });
    case COMPANY_ACTION.UPDATE_COMPANY_INFO_FAILURE:
      return StateUtil.setFailure('UpdateCompanyInfo', {
        ...state,
        ...action.payload,
      });

    default:
      return state;
  }
};
