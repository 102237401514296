import { ROUTE_API } from '../utils/route-util';
import HttpUtil from '../utils/http-util';

const sponsor = {
  getSponsorPackage: async () => await HttpUtil.get(ROUTE_API.getSponsorPackage),

  updateSponsorPackage: async ({ id, data }) =>
    await HttpUtil.post(ROUTE_API.updateSponsorPackage + '/' + id, data),
};

export default sponsor;
