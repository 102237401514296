import { takeLatest, put, call } from 'redux-saga/effects';
import {
  ADVERTISE_ACTION,
  createAdSuccess,
  createAdFailure,
  updateAdSuccess,
  updateAdFailure,
  getAdListSuccess,
  getAdListFailure,
  getAdDetailSuccess,
  getAdDetailFailure,
  banOrRejectAdSuccess,
  banOrRejectAdFailure,
  unbanAdSuccess,
  unbanAdFailure,
  getAdPackageSuccess,
  getAdPackageFailure,
  updateAdPackageSuccess,
  updateAdPackageFailure,
  updateAdStatusSuccess,
  updateAdStatusFailure
} from './action';
import AD_API from '../../api/advertise';

export default function* carPostSaga() {
  yield takeLatest(ADVERTISE_ACTION.CREATE_AD_REQUEST, CreateAdRequestWatcher);
  yield takeLatest(ADVERTISE_ACTION.UPDATE_AD_REQUEST, UpdateAdRequestWatcher);
  yield takeLatest(
    ADVERTISE_ACTION.GET_AD_LIST_REQUEST,
    getAdListRequestWatcher
  );
  yield takeLatest(
    ADVERTISE_ACTION.GET_AD_DETAIL_REQUEST,
    getAdDetailRequestWatcher
  );
  yield takeLatest(
    ADVERTISE_ACTION.BAN_OR_REJECT_AD_REQUEST,
    banOrRejectAdRequestWatcher
  );
  yield takeLatest(ADVERTISE_ACTION.UNBAN_AD_REQUEST, unbanAdRequestWatcher);
  yield takeLatest(
    ADVERTISE_ACTION.GET_AD_PACKAGE_REQUEST,
    getAdPackageRequestWatcher
  );
  yield takeLatest(
    ADVERTISE_ACTION.UPDATE_AD_PACKAGE_REQUEST,
    updateAdPackageRequestWatcher
  );
  yield takeLatest(ADVERTISE_ACTION.UPDATE_AD_STATUS_REQUEST, updateAdStatusRequestWatcher)
}

function* CreateAdRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(AD_API.createAd, data);
    const advertise = response.data;

    yield put(createAdSuccess({ advertise }));
  } catch (error) {
    const advertise = error.response.data;
    yield put(createAdFailure({ advertise }));
  }
}

function* UpdateAdRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(AD_API.updateAd, data);
    const updateAdRes = response.data;

    yield put(updateAdSuccess({ updateAdRes }));
  } catch (error) {
    const updateAdRes = error.response.data;
    yield put(updateAdFailure({ updateAdRes }));
  }
}

function* getAdListRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(AD_API.getAdList, data);
    const adList = response.data.advertisements;
    const metadata = response.data.metadata;
    const totalPage = metadata.totalPage;
    const totalAds = metadata.totalAdvertisements;

    yield put(getAdListSuccess({ adList, totalPage, totalAds }));
  } catch (error) {
    console.log(error);
    yield put(getAdListFailure());
  }
}

function* getAdDetailRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(AD_API.getAdDetail, data);
    const adDetail = response.data.advertisement;
    const totalSpent = response.data.totalSpent;

    yield put(getAdDetailSuccess({ adDetail, totalSpent }));
  } catch (error) {
    // console.log(error);
    yield put(getAdDetailFailure());
  }
}

function* banOrRejectAdRequestWatcher(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(AD_API.banOrRejectAd, { id, data });
    const banOrRejectAdRes = response.data;

    yield put(banOrRejectAdSuccess({ banOrRejectAdRes }));
  } catch (error) {
    // console.log(error.response);
    yield put(banOrRejectAdFailure());
  }
}

function* unbanAdRequestWatcher(action) {
  try {
    const id = action.payload;
    const response = yield call(AD_API.unbanAd, id);
    const unbanAdRes = response.data;

    yield put(unbanAdSuccess({ unbanAdRes }));
  } catch (error) {
    // console.log(error.response);
    yield put(unbanAdFailure());
  }
}

function* getAdPackageRequestWatcher() {
  try {
    const response = yield call(AD_API.getAdPackages);
    const adPackages = response.data.advertisementPackage;

    yield put(getAdPackageSuccess({ adPackages }));
  } catch (error) {
    yield put(getAdPackageFailure());
  }
}

function* updateAdPackageRequestWatcher(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(AD_API.updateAdPackage, { id, data });
    const updateAdPackageRes = response.data;

    yield put(updateAdPackageSuccess({ updateAdPackageRes }));
  } catch (error) {
    const updateAdPackageRes = error.response.data;
    yield put(updateAdPackageFailure({ updateAdPackageRes }));
  }
}

function* updateAdStatusRequestWatcher(action) {
  try {
    const { id, status } = action.payload;
    const response = yield call(AD_API.updateAdStatus, { id, status });
    const updateAdStatusRes = response.data;

    yield put(updateAdStatusSuccess({ updateAdStatusRes }));
  } catch (error) {
    const updateAdStatusRes = error.response.data;
    yield put(updateAdStatusFailure({ updateAdStatusRes }));
  }
}
