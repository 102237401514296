import { ADVERTISE_ACTION } from './action';
import StateUtil from '../../utils/state-util';

const initialState = {
  advertise: {},
  isLoadingCreateAd: false,
  isErrorCreateAd: false,

  adDetail: {},
  isLoadingGetAdDetail: false,
  isErrorGetAdDetail: false,
  totalSpent: 0,

  updateAdRes: {},
  isLoadingUpdateAd: false,
  isErrorUpdateAd: false,

  adList: [],
  totalPage: 0,
  totalAds: 0,
  isLoadingGetAdList: false,
  isErrorGetAdList: false,

  banOrRejectAdRes: {},
  isLoadingBanOrRejectAd: false,
  isErrorBanOrRejectAd: false,

  unbanAdRes: {},
  isLoadingUnbanAd: false,
  isErrorUnbanAd: false,

  adPackages: [],
  isLoadingGetAdPackages: false,
  isErrorGetAdPackages: false,

  updateAdPackageRes: {},
  isLoadingUpdateAdPackage: false,
  isErrorUpdateAdPackage: false,

  updateAdStatusRes: {},
  isLoadingUpdateAdStatus: false,
  isErrorUpdateStatus: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADVERTISE_ACTION.CREATE_AD_REQUEST:
      return StateUtil.setRequest('CreateAd', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.CREATE_AD_SUCCESS:
      return StateUtil.setSuccess('CreateAd', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.CREATE_AD_FAILURE:
      return StateUtil.setFailure('CreateAd', {
        ...state,
        ...action.payload,
      });

    case ADVERTISE_ACTION.UPDATE_AD_REQUEST:
      return StateUtil.setRequest('UpdateAd', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.UPDATE_AD_SUCCESS:
      return StateUtil.setSuccess('UpdateAd', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.UPDATE_AD_FAILURE:
      return StateUtil.setFailure('UpdateAd', {
        ...state,
        ...action.payload,
      });

    case ADVERTISE_ACTION.GET_AD_LIST_REQUEST:
      return StateUtil.setRequest('GetAdList', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.GET_AD_LIST_SUCCESS:
      return StateUtil.setSuccess('GetAdList', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.GET_AD_LIST_FAILURE:
      return StateUtil.setFailure('GetAdList', state);

    case ADVERTISE_ACTION.GET_AD_DETAIL_REQUEST:
      return StateUtil.setRequest('GetAdDetail', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.GET_AD_DETAIL_SUCCESS:
      return StateUtil.setSuccess('GetAdDetail', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.GET_AD_DETAIL_FAILURE:
      return StateUtil.setFailure('GetAdDetail', state);

    case ADVERTISE_ACTION.BAN_OR_REJECT_AD_REQUEST:
      return StateUtil.setRequest('BanOrRejectAd', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.BAN_OR_REJECT_AD_SUCCESS:
      return StateUtil.setSuccess('BanOrRejectAd', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.BAN_OR_REJECT_AD_FAILURE:
      return StateUtil.setFailure('BanOrRejectAd', state);

    case ADVERTISE_ACTION.UNBAN_AD_REQUEST:
      return StateUtil.setRequest('UnbanAd', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.UNBAN_AD_SUCCESS:
      return StateUtil.setSuccess('UnbanAd', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.UNBAN_AD_FAILURE:
      return StateUtil.setFailure('UnbanAd', state);

    case ADVERTISE_ACTION.GET_AD_PACKAGE_REQUEST:
      return StateUtil.setRequest('GetAdPackages', state);
    case ADVERTISE_ACTION.GET_AD_PACKAGE_SUCCESS:
      return StateUtil.setSuccess('GetAdPackages', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.GET_AD_PACKAGE_FAILURE:
      return StateUtil.setFailure('GetAdPackages', {
        ...state,
        ...action.payload,
      });

    case ADVERTISE_ACTION.UPDATE_AD_PACKAGE_REQUEST:
      return StateUtil.setRequest('UpdateAdPackage', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.UPDATE_AD_PACKAGE_SUCCESS:
      return StateUtil.setSuccess('UpdateAdPackage', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.UPDATE_AD_PACKAGE_FAILURE:
      return StateUtil.setFailure('UpdateAdPackage', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.UPDATE_AD_STATUS_REQUEST:
      return StateUtil.setRequest('UpdateAdStatus', {
        ...state,
        ...action.payload,
      });
    case ADVERTISE_ACTION.UPDATE_AD_STATUS_SUCCESS:
      return StateUtil.setSuccess('UpdateAdStatus', {
        ...state,
        ...action.payload,
      }); 
    case ADVERTISE_ACTION.UPDATE_AD_STATUS_FAILURE:
      return StateUtil.setFailure('UpdateAdStatus', {
        ...state,
        ...action.payload,
      });

    default:
      return state;
  }
};
