import { ROUTE_API } from '../utils/route-util';
import HttpUtil from '../utils/http-util';

const carPost = {
  getPostList: async (data) =>
    await HttpUtil.get(ROUTE_API.getPostList, { params: data }),

  getPostDetail: async (postId) => {
    const response = await HttpUtil.get(
      ROUTE_API.getPostDetail.replace(':id', postId)
    );
    return response;
  },

  banPost: async (data) => await HttpUtil.post(ROUTE_API.banPost, data),

  deletePost: async (data) => {
    return await HttpUtil.delete(ROUTE_API.deletePost, { data: { ...data } });
  },

  reportPost: async (data) => await HttpUtil.post(ROUTE_API.reportPost, data),
};

export default carPost;
