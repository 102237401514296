import { takeLatest, put, call } from 'redux-saga/effects';
import {
  AUTH_ACTION,
  loginSuccess,
  loginFailure,
  userSuccess,
  userFailure,
  logoutSuccess,
  logoutFailure,
  getDashboardSuccess,
  getDashboardFailure,
} from './action';
import AUTH_API from '../../api/authentication';

export default function* authSaga() {
  yield takeLatest(AUTH_ACTION.LOGIN_REQUEST, login);
  yield takeLatest(AUTH_ACTION.USER_REQUEST, user);
  yield takeLatest(AUTH_ACTION.LOGOUT_REQUEST, logout);
  yield takeLatest(AUTH_ACTION.GET_DASHBOARD_REQUEST, getDashboardWatcher);
}

function* login(action) {
  try {
    const data = action.payload;
    const response = yield call(AUTH_API.login, data);
    localStorage.setItem('token', response.data.token);
    localStorage.setItem('userInfo', JSON.stringify(response.data?.user));
    delete response.data.token;
    const userData = response.data;

    yield put(loginSuccess({ userData }));
  } catch (err) {
    const userData = err.response.data;
    yield put(loginFailure({ userData }));
  }
}

function* user() {
  try {
    const response = yield call(AUTH_API.user);
    const userData = response.data.user;

    yield put(userSuccess({ userData }));
  } catch (error) {
    const userData = error.response.data;
    yield put(userFailure({ userData }));
  }
}

function* logout() {
  try {
    localStorage.clear();
    const userData = undefined;
    const loginRes = undefined;

    yield put(logoutSuccess({ userData, loginRes }));
  } catch (err) {
    yield put(logoutFailure());
  }
}

function* getDashboardWatcher() {
  try {
    const response = yield call(AUTH_API.dashboard);
    const data = response.data;

    yield put(getDashboardSuccess({ data }));
  } catch (err) {
    yield put(getDashboardFailure());
  }
}
