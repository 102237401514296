import { ROUTE_API } from '../utils/route-util';
import HttpUtil from '../utils/http-util';

const auth = {
  login: async (data) => await HttpUtil.post(ROUTE_API.login, data),
  user: async () => await HttpUtil.get(`${ROUTE_API.staff}/me`),
  dashboard: async () => await HttpUtil.get(ROUTE_API.getDashboard),
};

export default auth;
