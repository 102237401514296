import { createMuiTheme } from '@material-ui/core/styles';
import { yellow } from '@material-ui/core/colors';

const textColor = '#707070';

export default createMuiTheme({
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        borderRadius: 0,
      },
    },
    MuiPickersToolbar: {
      toolbar: { backgroundColor: yellow[600] },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: yellow[600],
        '&:hover': {
          backgroundColor: yellow[400],
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: textColor,
        fontSize: 13,
      },
    },
    MuiListItem: {
      root: {
        color: textColor,
        '&$selected': {
          color: '#053779',
          backgroundColor: '#f2f2f2',
          fontWeight: 700,
        },
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: false,
      },
    },
  },
  palette: {
    primary: {
      main: '#053779',
    },
    // primary: {
    //   main: '#1A73E8',
    // },
    secondary: {
      main: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Siemreap',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      'sans-serif',
      'cursive',
    ].join(','),
  },
});
