import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import useRouter from '../hooks/useRouter';

import { ROUTE_PATH } from '../utils/route-util';
import ProtectedRoute from '../utils/route-protected-util';

const Loading = lazy(() => import('../component/Loading'));

const PrivateRoute = lazy(() => import('./private'));

const Login = lazy(() => import('../pages/Login'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));

export default function Routes() {
  const { goTo, location } = useRouter();
  const { isAuth } = useAuth();

  useEffect(() => {
    // Preventing user going back to login page after login
    if (
      isAuth() &&
      (location.pathname === '/login' ||
        location.pathname === '/forgot-password')
    )
      goTo(ROUTE_PATH.dashboard)();
    if (!isAuth()) goTo(ROUTE_PATH.login)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path={ROUTE_PATH.login}>
          <Login />
        </Route>
        <Route exact path={ROUTE_PATH.forgotPassword}>
          <ForgotPassword />
        </Route>
        <ProtectedRoute path={ROUTE_PATH.root}>
          <PrivateRoute />
        </ProtectedRoute>
      </Switch>
    </Suspense>
  );
}
