import { takeLatest, put, call } from 'redux-saga/effects';
import {
  PAYMENT_ACTION,
  getPaymentListSuccess,
  getPaymentListFailure,
} from './action';
import PAYMENT_API from '../../api/payment';

export default function* paymentSaga() {
  yield takeLatest(
    PAYMENT_ACTION.GET_PAYMENT_LIST_REQUEST,
    getAdListRequestWatcher
  );
}

function* getAdListRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(PAYMENT_API.getPaymentList, data);
    const paymentList = response.data.transaction;
    const metadata = response.data.metadata;
    const totalPage = metadata.totalPage;
    const totalPayments = metadata.totalTransactions;

    yield put(getPaymentListSuccess({ paymentList, totalPage, totalPayments }));
  } catch (error) {
    console.log(error);
    yield put(getPaymentListFailure());
  }
}
