import { BLOG_CATEGORY_ACTION } from './action';
import StateUtil from '../../utils/state-util';

const initialState = {
  createBlogCategoryRes: {},
  isLoadingCreateBlogCategory: false,
  isErrorCreateBlogCategory: false,

  blogCategoryList: [],
  isLoadingBlogCategoryList: false,
  isErrorBlogCategoryList: false,

  deleteBlogCategoryRes: {},
  isLoadingDeleteBlogCategory: false,
  isErrorDeleteBlogCategory: false,

  updateBlogCategoryRes: {},
  isLoadingUpdateBlogCategory: false,
  isErrorUpdateBlogCategory: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BLOG_CATEGORY_ACTION.CREATE_BLOG_CATEGORY_REQUEST:
      return StateUtil.setRequest('CreateBlogCategory', {
        ...state,
        ...action.payload,
      });
    case BLOG_CATEGORY_ACTION.CREATE_BLOG_CATEGORY_SUCCESS:
      return StateUtil.setSuccess('CreateBlogCategory', {
        ...state,
        ...action.payload,
      });
    case BLOG_CATEGORY_ACTION.CREATE_BLOG_CATEGORY_FAILURE:
      return StateUtil.setFailure('CreateBlogCategory', {
        ...state,
        ...action.payload,
      });
    case BLOG_CATEGORY_ACTION.LIST_BLOG_CATEGORY_REQUEST:
      return StateUtil.setRequest('BlogCategoryList', {
        ...state,
        ...action.payload,
      });
    case BLOG_CATEGORY_ACTION.LIST_BLOG_CATEGORY_SUCCESS:
      return StateUtil.setSuccess('BlogCategoryList', {
        ...state,
        ...action.payload,
      });
    case BLOG_CATEGORY_ACTION.LIST_BLOG_CATEGORY_FAILURE:
      return StateUtil.setFailure('BlogCategoryList', {
        ...state,
        ...action.payload,
      });
    case BLOG_CATEGORY_ACTION.DELETE_BLOG_CATEGORY_REQUEST:
      return StateUtil.setRequest('DeleteBlogCategory', {
        ...state,
        ...action.payload,
      });
    case BLOG_CATEGORY_ACTION.DELETE_BLOG_CATEGORY_SUCCESS:
      return StateUtil.setSuccess('DeleteBlogCategory', {
        ...state,
        ...action.payload,
      });
    case BLOG_CATEGORY_ACTION.DELETE_BLOG_CATEGORY_FAILURE:
      return StateUtil.setFailure('DeleteBlogCategoty', {
        ...state,
        ...action.payload,
      });
    case BLOG_CATEGORY_ACTION.UPDATE_BLOG_CATEGORY_REQUEST:
      return StateUtil.setRequest('UpdateBlogCategory', {
        ...state,
        ...action.payload,
      });
    case BLOG_CATEGORY_ACTION.UPDATE_BLOG_CATEGORY_SUCCESS:
      return StateUtil.setSuccess('UpdateBlogCategory', {
        ...state,
        ...action.payload,
      });
    case BLOG_CATEGORY_ACTION.UPDATE_BLOG_CATEGORY_FAILURE:
      return StateUtil.setFailure('UpdateBlogCategory', {
        ...state,
        ...action.payload,
      });
    default:
      return state;
  }
};
