import { takeLatest, put, call } from "redux-saga/effects";
import {
  STAFFS_ACTION,
  getRoleSuccess,
  getRoleFailure,
  staffSuccess,
  staffFailure,
} from "./action";
import STAFFS_API from "../../api/staffs";

export default function* staffsSaga() {
  yield takeLatest(STAFFS_ACTION.GET_ROLE_REQUEST, getRole);
  yield takeLatest(STAFFS_ACTION.STAFF_REQUEST, staff);
}

function* getRole() {
  try {
    const response = yield call(STAFFS_API.roles);
    const roleList = response.data.roles;

    yield put(getRoleSuccess({ roleList }));
  } catch (error) {
    const roleList = error.response.data;
    yield put(getRoleFailure(roleList));
  }
}

function* staff(action) {
  try {
    const data = action.payload;
    const response = yield call(STAFFS_API.staff, data);
    const staffData = response.data.user;

    yield put(staffSuccess({ staffData }));
  } catch (error) {
    const staffData = error.response.data;
    yield put(staffFailure({ staffData }));
  }
}
