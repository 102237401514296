import { AUTH_ACTION } from './action';
import StateUtil from '../../utils/state-util';

const initialState = {
  isLogining: false,
  isLoginingError: false,

  isRequestingUser: false,
  isRequestingUserError: false,

  data: {},
  isLoadingDashboard: false,
  isErrorDashboard: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_ACTION.LOGIN_REQUEST:
      return {
        ...state,
        ...action.payload,
        isLogining: true,
        isLoginingError: false,
      };
    case AUTH_ACTION.LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isLogining: false,
        isLoginingError: false,
      };
    case AUTH_ACTION.LOGIN_FAILURE:
      return {
        ...state,
        ...action.payload,
        isLogining: false,
        isLoginingError: true,
      };

    case AUTH_ACTION.USER_REQUEST:
      return {
        ...state,
        isRequestingUser: true,
        isRequestingUserError: false,
      };
    case AUTH_ACTION.USER_SUCCESS:
      return {
        ...state,
        ...action.payload,
        isRequestingUser: false,
        isRequestingUserError: false,
      };
    case AUTH_ACTION.USER_FAILURE:
      return {
        ...state,
        ...action.payload,
        isRequestingUser: false,
        isRequestingUserError: true,
      };

    case AUTH_ACTION.LOGOUT_REQUEST:
      return StateUtil.setRequest('Logout', state);

    case AUTH_ACTION.LOGOUT_SUCCESS:
      return StateUtil.setSuccess('Logout', {
        ...state,
        ...action.payload,
      });

    case AUTH_ACTION.LOGOUT_FAILURE:
      return StateUtil.setFailure('Logout', state);

    case AUTH_ACTION.GET_DASHBOARD_REQUEST:
      return StateUtil.setRequest('Dashboard', state);
    case AUTH_ACTION.GET_DASHBOARD_SUCCESS:
      return StateUtil.setSuccess('Dashboard', {
        ...state,
        ...action.payload,
      });
    case AUTH_ACTION.GET_DASHBOARD_FAILURE:
      return StateUtil.setFailure('Dashboard', state);

    default:
      return state;
  }
};
