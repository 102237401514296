import { ROUTE_API } from '../utils/route-util';
import HttpUtil from '../utils/http-util';

const membership = {
  getMembershipList: async () => await HttpUtil.get(ROUTE_API.membershipList),

  getMembershipPlan: async () => await HttpUtil.get(ROUTE_API.membershipPlan),

  getMembership: async (id) => await HttpUtil.get(ROUTE_API.getMembership + '/' + id),

  editMembership: async (data) =>
    await HttpUtil.put(ROUTE_API.editMembership + '/' + data.id, data.data),
};

export default membership;
