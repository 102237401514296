export const PAYMENT_ACTION = {
  GET_PAYMENT_LIST_REQUEST: 'GET_PAYMENT_LIST_REQUEST',
  GET_PAYMENT_LIST_SUCCESS: 'GET_PAYMENT_LIST_SUCCESS',
  GET_PAYMENT_LIST_FAILURE: 'GET_PAYMENT_LIST_FAILURE',
};

export const getPaymentListRequest = (data) => ({
  type: PAYMENT_ACTION.GET_PAYMENT_LIST_REQUEST,
  payload: data,
});
export const getPaymentListSuccess = ({
  paymentList,
  totalPage,
  totalPayments,
}) => ({
  type: PAYMENT_ACTION.GET_PAYMENT_LIST_SUCCESS,
  payload: { paymentList, totalPage, totalPayments },
});
export const getPaymentListFailure = () => ({
  type: PAYMENT_ACTION.GET_PAYMENT_LIST_FAILURE,
});
