export const ROUTE_PATH = {
  root: '/',

  login: '/login',
  forgotPassword: '/forgot-password',

  dashboard: '/dashboard',
  newUser: '/dashboard/new-user',
  editUser: '/dashboard/edit-user',
  manageUser: '/dashboard/manage-user',
  manageCustomer: '/dashboard/manage-customer',
  companyInfo: '/dashboard/company-info',

  blog: '/dashboard/blog',
  blogList: '/dashboard/blog-list',
  blogCategory: '/dashboard/blog-category',

  howToUse: '/dashboard/how-to-use',

  membershipList: '/dashboard/membership-list',
  membership: '/dashboard/membership/:id',

  postList: '/dashboard/post/list',
  sponsor: '/dashboard/sponsor',

  createAd: '/dashboard/ad/create',
  updateAd: '/dashboard/ad/:id/edit',
  adList: '/dashboard/ad/list',
  packages: '/dashboard/packages',

  transactions: '/dashboard/transaction/list',
};

export const ROUTE_API = {
  root: process.env.REACT_APP_API_URL,
  // website: process.env.REACT_APP_WEBSITE_API_URL,

  staff: '/api/v1/staffs',
  login: '/api/v1/staffs/login',
  roles: '/api/v1/staffs/roles',

  create: '/api/v1/staffs/create',
  update: '/api/v1/staffs/update',
  staffList: '/api/v1/staffs/list',
  deleteStaffs: '/api/v1/staffs/delete',
  changePassword: '/api/v1/staffs/change-password',
  toggleStaffs: '/api/v1/staffs/toggle-status',
  manualResetPw: '/api/v1/staffs/manual-reset-password',

  resetPw: '/api/v1/staffs/reset-password',
  forgotPw: '/api/v1/staffs/forgot-password',
  confirmCode: '/api/v1/staffs/confirm-code',

  updateCompanyInfo: '/api/v1/company',
  uploadImage: '/api/v1/aws-s3/upload',

  getBlogDetail: '/api/v1/blogs/detail',
  createBlog: '/api/v1/blogs/create',
  blogList: '/api/v1/blogs/list',
  updateBlog: '/api/v1/blogs/update',
  deleteBlog: '/api/v1/blogs/delete',
  toggleBlogStatus: '/api/v1/blogs/toggle-status',

  createBlogCategory: '/api/v1/blogcategory/create',
  blogCategoryList: '/api/v1/blogcategory/list',
  deleteBlogCategory: '/api/v1/blogcategory/delete',
  updateBlogCategory: '/api/v1/blogcategory/update',

  getCusList: '/api/v1/customers',
  updateMembership: '/api/v1/customers/update-membership',
  memberships: '/api/v1/users/memberships',

  membershipList: '/memberships',
  membershipPlan: '/api/v1/membership/plan',
  getMembership: '/api/v1/membership/edit',
  editMembership: '/api/v1/membership/update',

  getPostList: '/api/v1/posts/list',
  getPostDetail: '/api/v1/posts/detail/:id',
  banPost: '/api/v1/posts/ban',
  deletePost: '/api/v1/posts/delete',
  reportPost: '/api/v1/posts/report',
  getPostPage: '/api/v1/customers/:id/getPage',

  getAdPackage: '/api/v1/advertisements/get-package',
  updateAdPackage: '/api/v1/advertisements/update-package/:id',

  getSponsorPackage: '/api/v1/posts/sponsor-package',
  updateSponsorPackage: '/api/v1/posts/update-sponsor-package',

  getAdList: '/api/v1/advertisements/list',
  getAdDetail: '/api/v1/advertisements/detail/:id',
  createAd: '/api/v1/advertisements/create',
  updateAd: '/api/v1/advertisements/update',
  rejectAd: '/api/v1/advertisements/reject',
  unbanAd: '/api/v1/advertisements/unbanned',
  updateAdStatus: '/api/v1/advertisements/update-status',

  getTransactionList: '/api/v1/transactions/list',

  getDashboard: '/api/v1/dashboard',
};
