import { ROUTE_API } from '../utils/route-util';
import HttpUtil from '../utils/http-util';

const company = {
  createBlog: async (data) => await HttpUtil.post(ROUTE_API.createBlog, data),

  listBlog: async (data) =>
    await HttpUtil.get(ROUTE_API.blogList, {
      params: {
        ...data,
      },
    }),

  getBlogDetail: async (data) => await HttpUtil.get(ROUTE_API.getBlogDetail + '/' + data),

  updateBlog: async (data) =>
    await HttpUtil.put(ROUTE_API.updateBlog + '/' + data.id, data.data),

  deleteBlog: async (data) =>
    await HttpUtil.delete(ROUTE_API.deleteBlog, {
      params: {
        ...data,
      },
    }),

  toggleBlog: async (data) =>
    await HttpUtil.put(ROUTE_API.toggleBlogStatus + '/' + data.id, data.data),
};

export default company;
