import { combineReducers } from 'redux';
import authReducer from './authentication/reducer';
import staffsReducer from './staffs/reducer';
import companyReducer from './company/reducer';
import blogReducer from './blog/reducer';
import blogCategoryReducer from './blogCategory/reducer';
import customerReducer from './customer/reducer';
import membershipReducer from './membership/reducer';
import carPostReducer from './carPost/reducer';
import sponsorReducer from './sponsor/reducer';
import advertiseReducer from './advertise/reducer';
import paymentReducer from './payment/reducer';

const allReducer = combineReducers({
  authReducer,
  staffsReducer,
  companyReducer,
  blogReducer,
  blogCategoryReducer,
  customerReducer,
  membershipReducer,
  carPostReducer,
  sponsorReducer,
  advertiseReducer,
  paymentReducer,
});

export default allReducer;
