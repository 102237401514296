export const AUTH_ACTION = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  USER_REQUEST: 'USER_REQUEST',
  USER_SUCCESS: 'USER_SUCCESS',
  USER_FAILURE: 'USER_FAILURE',

  LOGOUT_REQUEST: 'LOGOUT_REQUEST',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  GET_DASHBOARD_REQUEST: 'GET_DASHBOARD_REQUEST',
  GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
  GET_DASHBOARD_FAILURE: 'GET_DASHBOARD_FAILURE',
};

export const loginRequest = (data) => ({
  type: AUTH_ACTION.LOGIN_REQUEST,
  payload: data,
});
export const loginSuccess = ({ userData }) => ({
  type: AUTH_ACTION.LOGIN_SUCCESS,
  payload: { userData },
});
export const loginFailure = ({ userData }) => ({
  type: AUTH_ACTION.LOGIN_FAILURE,
  payload: { userData },
});

export const userRequest = () => ({
  type: AUTH_ACTION.USER_REQUEST,
});
export const userSuccess = ({ userData }) => ({
  type: AUTH_ACTION.USER_SUCCESS,
  payload: { userData },
});
export const userFailure = ({ userData }) => ({
  type: AUTH_ACTION.USER_FAILURE,
  payload: userData,
});

export const logoutRequest = () => ({
  type: AUTH_ACTION.LOGOUT_REQUEST,
});
export const logoutSuccess = ({ userData, loginRes }) => ({
  type: AUTH_ACTION.LOGOUT_SUCCESS,
  payload: { userData, loginRes },
});
export const logoutFailure = () => ({
  type: AUTH_ACTION.LOGOUT_FAILURE,
});

export const getDashboardRequest = () => ({
  type: AUTH_ACTION.GET_DASHBOARD_REQUEST,
});
export const getDashboardSuccess = ({ data }) => ({
  type: AUTH_ACTION.GET_DASHBOARD_SUCCESS,
  payload: { data },
});
export const getDashboardFailure = () => ({
  type: AUTH_ACTION.GET_DASHBOARD_FAILURE,
});
