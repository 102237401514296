import { useHistory, useParams, useLocation } from 'react-router-dom';
const useRouter = () => {
  const history = useHistory();
  const urlParams = useParams();
  const location = useLocation();

  const goBack = () => history.goBack();

  const goTo =
    (routeName, id = ':id') =>
    () => {
      history.push(routeName.replace(':id', id));
      // history.push('/dashboard/blog-category');
    };

  const goToWithData =
    (routeName, id = ':id') =>
    (data) => {
      history.push({ pathname: routeName.replace(':id', id), state: data });
    };

  return {
    history,
    urlParams,
    location,

    goBack,
    goTo,
    goToWithData,
  };
};

export default useRouter;
