import { takeLatest, put, call } from 'redux-saga/effects';
import {
  BLOG_ACTION,
  createBlogSuccess,
  createBlogFailure,
  listBlogSuccess,
  listBlogFailure,
  getBlogDetailSuccess,
  getBlogDetailFailure,
  updateBlogSuccess,
  updateBlogFailure,
  deleteBlogSuccess,
  deleteBlogFailure,
  toggletatusSuccess,
  toggleStatusFailure,
} from './action';
import BLOG_API from '../../api/blog';

export default function* authSaga() {
  yield takeLatest(BLOG_ACTION.CREATE_BLOG_REQUEST, createBlogWatcher);
  yield takeLatest(BLOG_ACTION.LIST_BLOG_REQUEST, listBlogWatcher);
  yield takeLatest(BLOG_ACTION.GET_BLOG_DETAIL_REQUEST, getBlogDetailWatcher);
  yield takeLatest(BLOG_ACTION.UPDATE_BLOG_REQUEST, updateBlogWatcher);
  yield takeLatest(BLOG_ACTION.DELETE_BLOG_REQUEST, deleteBlogWatcher);
  yield takeLatest(BLOG_ACTION.TOGGLE_STATUS_REQUEST, toggleStatusWatcher);
}

function* createBlogWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(BLOG_API.createBlog, data);
    const createBlogRes = response.data;

    yield put(createBlogSuccess({ createBlogRes }));
  } catch (err) {
    const createBlogRes = err.response.data;
    yield put(createBlogFailure({ createBlogRes }));
  }
}

function* listBlogWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(BLOG_API.listBlog, data);
    const blogList = response.data;

    yield put(listBlogSuccess({ blogList }));
  } catch (err) {
    const blogList = err.response.data;
    yield put(listBlogFailure({ blogList }));
  }
}

function* getBlogDetailWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(BLOG_API.getBlogDetail, data);
    const blogDetail = response.data.blog;
    yield put(getBlogDetailSuccess({ blogDetail }));
  } catch (err) {
    const blogDetail = err.response.data;
    yield put(getBlogDetailFailure({ blogDetail }));
  }
}

function* updateBlogWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(BLOG_API.updateBlog, data);
    const updateBlogRes = response.data;

    yield put(updateBlogSuccess({ updateBlogRes }));
  } catch (err) {
    const updateBlogRes = err.response.data;
    yield put(updateBlogFailure({ updateBlogRes }));
  }
}

function* deleteBlogWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(BLOG_API.deleteBlog, data);
    const deleteBlogRes = response.data;

    yield put(deleteBlogSuccess({ deleteBlogRes }));
  } catch (err) {
    const deleteBlogRes = err.response.data;
    yield put(deleteBlogFailure({ deleteBlogRes }));
  }
}

function* toggleStatusWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(BLOG_API.toggleBlog, data);
    const toggleStatusRes = response.data;

    yield put(toggletatusSuccess({ toggleStatusRes }));
  } catch (err) {
    const toggleStatusRes = err.response.data;
    yield put(toggleStatusFailure({ toggleStatusRes }));
  }
}
