export const MEMBERSHIP_ACTION = {
  GET_MEMBERSHIP_LIST_REQUEST: 'GET_MEMBERSHIP_LIST_REQUEST',
  GET_MEMBERSHIP_LIST_SUCCESS: 'GET_MEMBERSHIP_LIST_SUCCESS',
  GET_MEMBERSHIP_LIST_FAILURE: 'GET_MEMBERSHIP_LIST_FAILURE',

  GET_MEMBERSHIP_PLAN_REQUEST: 'GET_MEMBERSHIP_PLAN_REQUEST',
  GET_MEMBERSHIP_PLAN_SUCCESS: 'GET_MEMBERSHIP_PLAN_SUCCESS',
  GET_MEMBERSHIP_PLAN_FAILURE: 'GET_MEMBERSHIP_PLAN_FAILURE',

  GET_MEMBERSHIP_REQUEST: 'GET_MEMBERSHIP_REQUEST',
  GET_MEMBERSHIP_SUCCESS: 'GET_MEMBERSHIP_SUCCESS',
  GET_MEMBERSHIP_FAILURE: 'GET_MEMBERSHIP_FAILURE',

  UPDATE_MEMBERSHIP_TYPE_REQUEST: 'UPDATE_MEMBERSHIP_TYPE_REQUEST',
  UPDATE_MEMBERSHIP_TYPE_SUCCESS: 'UPDATE_MEMBERSHIP_TYPE_SUCCESS',
  UPDATE_MEMBERSHIP_TYPE_FAILURE: 'UPDATE_MEMBERSHIP_TYPE_FAILURE',
};

export const getMembershipListRequest = () => ({
  type: MEMBERSHIP_ACTION.GET_MEMBERSHIP_LIST_REQUEST,
});
export const getMembershipListSuccess = ({ membershipList }) => ({
  type: MEMBERSHIP_ACTION.GET_MEMBERSHIP_LIST_SUCCESS,
  payload: { membershipList },
});
export const getMembershipListFailure = () => ({
  type: MEMBERSHIP_ACTION.GET_MEMBERSHIP_LIST_FAILURE,
});

export const getMembershipPlanRequest = () => ({
  type: MEMBERSHIP_ACTION.GET_MEMBERSHIP_PLAN_REQUEST,
});
export const getMembershipPlanSuccess = ({ membershipPlan }) => ({
  type: MEMBERSHIP_ACTION.GET_MEMBERSHIP_PLAN_SUCCESS,
  payload: { membershipPlan },
});
export const getMembershipPlanFailure = () => ({
  type: MEMBERSHIP_ACTION.GET_MEMBERSHIP_PLAN_FAILURE,
});

export const getMembershipRequest = (id) => ({
  type: MEMBERSHIP_ACTION.GET_MEMBERSHIP_REQUEST,
  payload: id,
});
export const getMembershipSuccess = ({ membership }) => ({
  type: MEMBERSHIP_ACTION.GET_MEMBERSHIP_SUCCESS,
  payload: { membership },
});
export const getMembershipFailure = () => ({
  type: MEMBERSHIP_ACTION.GET_MEMBERSHIP_FAILURE,
});

export const updateMembershipTypeRequest = (data) => ({
  type: MEMBERSHIP_ACTION.UPDATE_MEMBERSHIP_TYPE_REQUEST,
  payload: data,
});
export const updateMembershipTypeSuccess = ({ updateMembership }) => ({
  type: MEMBERSHIP_ACTION.UPDATE_MEMBERSHIP_TYPE_SUCCESS,
  payload: { updateMembership },
});
export const updateMembershipTypeFailure = ({ updateMembership }) => ({
  type: MEMBERSHIP_ACTION.UPDATE_MEMBERSHIP_TYPE_FAILURE,
  payload: { updateMembership },
});
