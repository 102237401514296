import { takeLatest, put, call } from 'redux-saga/effects';
import {
  CUSTOMER_ACTION,
  getCusListSuccess,
  getCusListFailure,
  getCusPagesSuccess,
  getCusPagesFailure,
  updateMembershipSuccess,
  updateMembershipFailure,
} from './action';
import CUSTOMER_API from '../../api/customer';

export default function* staffsSaga() {
  yield takeLatest(
    CUSTOMER_ACTION.GET_CUS_LIST_REQUEST,
    getCusListRequestWatcher
  );
  yield takeLatest(
    CUSTOMER_ACTION.GET_CUS_PAGES_REQUEST,
    getCusPagesRequestWatcher
  );
  yield takeLatest(
    CUSTOMER_ACTION.UPDATE_MEMBERSHIP_REQUEST,
    updateMembershipRequestWatcher
  );
}

function* getCusListRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(CUSTOMER_API.getCusList, data);
    const customers = response.data.customers;
    const metadata = response.data.metadata;
    const totalPage = metadata.totalPage;
    const totalCus = metadata.totalCustomers;

    yield put(getCusListSuccess({ customers, totalCus, totalPage }));
  } catch (error) {
    const customers = error.response.data;
    yield put(getCusListFailure({ customers }));
  }
}

function* getCusPagesRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(CUSTOMER_API.getCusPages, data);
    const pages = response.data.pages;

    yield put(getCusPagesSuccess({ pages }));
  } catch (error) {
    yield put(getCusPagesFailure());
  }
}

function* updateMembershipRequestWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(CUSTOMER_API.updateMembership, data);
    const updateMembershipRes = response.data;

    yield put(updateMembershipSuccess({ updateMembershipRes }));
  } catch (error) {
    const updateMembershipRes = error.response.data;
    yield put(updateMembershipFailure({ updateMembershipRes }));
  }
}
