import { takeLatest, put, call } from 'redux-saga/effects';
import {
  SPONSOR_ACTION,
  getSponsorPackageSuccess,
  getSponsorPackageFailure,
  updateSponsorPackageSuccess,
  updateSponsorPackageFailure,
} from './action';
import SPONSOR_API from '../../api/sponsor';

export default function* SponsorSaga() {
  yield takeLatest(SPONSOR_ACTION.GET_SPONSOR_PACKAGE_REQUEST, getSponsorRequestWatcher);
  yield takeLatest(
    SPONSOR_ACTION.UPDATE_SPONSOR_PACKAGE_REQUEST,
    updateSponsorPackageRequestWatcher
  );
}

function* getSponsorRequestWatcher() {
  try {
    const response = yield call(SPONSOR_API.getSponsorPackage);
    const sponsorPackage = response.data.sponsorPackage;

    yield put(getSponsorPackageSuccess({ sponsorPackage }));
  } catch (error) {
    yield put(getSponsorPackageFailure());
  }
}

function* updateSponsorPackageRequestWatcher(action) {
  try {
    const { id, data } = action.payload;
    const response = yield call(SPONSOR_API.updateSponsorPackage, { id, data });
    const updateSponsorRes = response.data;

    yield put(updateSponsorPackageSuccess({ updateSponsorRes }));
  } catch (error) {
    const updateSponsorRes = error.response.data;
    yield put(updateSponsorPackageFailure({ updateSponsorRes }));
  }
}
