import { takeLatest, put, call } from 'redux-saga/effects';
import {
  COMPANY_ACTION,
  updateCompnayInfoSuccess,
  updateCompnayInfoFailure,
  getCompnayInfoSuccess,
  getCompnayInfoFailure,
} from './action';
import COMPANY_API from '../../api/company';

export default function* authSaga() {
  yield takeLatest(
    COMPANY_ACTION.GET_COMPANY_INFO_REQUEST,
    getCompanyInfoWatcher
  );
  yield takeLatest(
    COMPANY_ACTION.UPDATE_COMPANY_INFO_REQUEST,
    updateCompanyInfoWatcher
  );
}

function* getCompanyInfoWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(COMPANY_API.getCompanyInfo, data);
    const companyInfo = response.data.companyInfo;

    yield put(getCompnayInfoSuccess({ companyInfo }));
  } catch (err) {
    const companyInfo = err.response.data;
    yield put(getCompnayInfoFailure({ companyInfo }));
  }
}

function* updateCompanyInfoWatcher(action) {
  try {
    const data = action.payload;
    const response = yield call(COMPANY_API.updateCompanyInfo, data);
    const updateCompanyInfoRes = response.data;

    yield put(updateCompnayInfoSuccess({ updateCompanyInfoRes }));
  } catch (err) {
    const updateCompanyInfoRes = err.response.data;
    yield put(updateCompnayInfoFailure({ updateCompanyInfoRes }));
  }
}
