import { MEMBERSHIP_ACTION } from './action';
import StateUtil from '../../utils/state-util';

const initialState = {
  membershipList: [],
  isLoadingGetMembershipList: false,
  isErrorGetMembershipList: false,

  membershipPlan: [],
  isLoadingGetMembershipPlan: false,
  isErrorGetMembershipPlan: false,

  membership: {},
  isLoadingGetMembership: false,
  isErrorGetMembership: false,

  updateMembership: {},
  isLoadingUpdateMembershipType: false,
  isErrorUpdateMembershipType: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MEMBERSHIP_ACTION.GET_MEMBERSHIP_LIST_REQUEST:
      return StateUtil.setRequest('GetMembershipList', state);
    case MEMBERSHIP_ACTION.GET_MEMBERSHIP_LIST_SUCCESS:
      return StateUtil.setSuccess('GetMembershipList', {
        ...state,
        ...action.payload,
      });
    case MEMBERSHIP_ACTION.GET_MEMBERSHIP_LIST_FAILURE:
      return StateUtil.setFailure('GetMembershipList', {
        ...state,
        ...action.payload,
      });

    case MEMBERSHIP_ACTION.GET_MEMBERSHIP_PLAN_REQUEST:
      return StateUtil.setRequest('GetMembershipPlan', state);
    case MEMBERSHIP_ACTION.GET_MEMBERSHIP_PLAN_SUCCESS:
      return StateUtil.setSuccess('GetMembershipPlan', {
        ...state,
        ...action.payload,
      });
    case MEMBERSHIP_ACTION.GET_MEMBERSHIP_PLAN_FAILURE:
      return StateUtil.setFailure('GetMembershipPlan', {
        ...state,
        ...action.payload,
      });

    case MEMBERSHIP_ACTION.GET_MEMBERSHIP_REQUEST:
      return StateUtil.setRequest('GetMembership', {
        ...state,
        ...action.payload,
      });
    case MEMBERSHIP_ACTION.GET_MEMBERSHIP_SUCCESS:
      return StateUtil.setSuccess('GetMembership', {
        ...state,
        ...action.payload,
      });
    case MEMBERSHIP_ACTION.GET_MEMBERSHIP_FAILURE:
      return StateUtil.setFailure('GetMembership', {
        ...state,
        ...action.payload,
      });

    case MEMBERSHIP_ACTION.UPDATE_MEMBERSHIP_TYPE_REQUEST:
      return StateUtil.setRequest('UpdateMembershipType', {
        ...state,
        ...action.payload,
      });
    case MEMBERSHIP_ACTION.UPDATE_MEMBERSHIP_TYPE_SUCCESS:
      return StateUtil.setSuccess('UpdateMembershipType', {
        ...state,
        ...action.payload,
      });
    case MEMBERSHIP_ACTION.UPDATE_MEMBERSHIP_TYPE_FAILURE:
      return StateUtil.setFailure('UpdateMembershipType', {
        ...state,
        ...action.payload,
      });

    default:
      return state;
  }
};
