export const BLOG_ACTION = {
  CREATE_BLOG_REQUEST: 'CREATE_BLOG_REQUEST',
  CREATE_BLOG_SUCCESS: 'CREATE_BLOG_SUCCESS',
  CREATE_BLOG_FAILURE: 'CREATE_BLOG_FAILURE',

  LIST_BLOG_REQUEST: 'LIST_BLOG_REQUEST',
  LIST_BLOG_SUCCESS: 'LIST_BLOG_SUCCESS',
  LIST_BLOG_FAILURE: 'LIST_BLOG_FAILURE',

  GET_BLOG_DETAIL_REQUEST: 'GET_BLOG_DETAIL_REQUEST',
  GET_BLOG_DETAIL_SUCCESS: 'GET_BLOG_DETAIL_SUCCESS',
  GET_BLOG_DETAIL_FAILURE: 'GET_BLOG_DETAIL_FAILURE',

  UPDATE_BLOG_REQUEST: 'UPDATE_BLOG_REQUEST',
  UPDATE_BLOG_SUCCESS: 'UPDATE_BLOG_SUCCESS',
  UPDATE_BLOG_FAILURE: 'UPDATE_BLOG_FAILURE',

  DELETE_BLOG_REQUEST: 'DELETE_BLOG_REQUEST',
  DELETE_BLOG_SUCCESS: 'DELETE_BLOG_SUCCESS',
  DELETE_BLOG_FAILURE: 'DELETE_BLOG_FAILURE',

  TOGGLE_STATUS_REQUEST: 'TOGGLE_STATUS_REQUEST',
  TOGGLE_STATUS_SUCCESS: 'TOGGLE_STATUS_SUCCESS',
  TOGGLE_STATUS_FAILURE: 'TOGGLE_STATUS_FAILURE',
};

export const createBlogRequest = (data) => ({
  type: BLOG_ACTION.CREATE_BLOG_REQUEST,
  payload: data,
});
export const createBlogSuccess = ({ createBlogRes }) => ({
  type: BLOG_ACTION.CREATE_BLOG_SUCCESS,
  payload: { createBlogRes },
});
export const createBlogFailure = ({ createBlogRes }) => ({
  type: BLOG_ACTION.CREATE_BLOG_FAILURE,
  payload: { createBlogRes },
});

export const listBlogRequest = (data) => ({
  type: BLOG_ACTION.LIST_BLOG_REQUEST,
  payload: data,
});
export const listBlogSuccess = ({ blogList }) => ({
  type: BLOG_ACTION.LIST_BLOG_SUCCESS,
  payload: { blogList },
});
export const listBlogFailure = ({ blogList }) => ({
  type: BLOG_ACTION.LIST_BLOG_FAILURE,
  payload: { blogList },
});

export const getBlogDetailRequest = (data) => ({
  type: BLOG_ACTION.GET_BLOG_DETAIL_REQUEST,
  payload: data,
});
export const getBlogDetailSuccess = ({ blogDetail }) => ({
  type: BLOG_ACTION.GET_BLOG_DETAIL_SUCCESS,
  payload: { blogDetail },
});
export const getBlogDetailFailure = ({ blogDetail }) => ({
  type: BLOG_ACTION.GET_BLOG_DETAIL_FAILURE,
  payload: { blogDetail },
});

export const updateBlogRequest = (data) => ({
  type: BLOG_ACTION.UPDATE_BLOG_REQUEST,
  payload: data,
});
export const updateBlogSuccess = ({ updateBlogRes }) => ({
  type: BLOG_ACTION.UPDATE_BLOG_SUCCESS,
  payload: { updateBlogRes },
});
export const updateBlogFailure = ({ updateBlogRes }) => ({
  type: BLOG_ACTION.UPDATE_BLOG_FAILURE,
  payload: { updateBlogRes },
});

export const deleteBlogRequest = (data) => ({
  type: BLOG_ACTION.DELETE_BLOG_REQUEST,
  payload: data,
});
export const deleteBlogSuccess = ({ deleteBlogRes }) => ({
  type: BLOG_ACTION.DELETE_BLOG_SUCCESS,
  payload: { deleteBlogRes },
});
export const deleteBlogFailure = ({ deleteBlogRes }) => ({
  type: BLOG_ACTION.DELETE_BLOG_FAILURE,
  payload: { deleteBlogRes },
});

export const toggleStatusRequest = (data) => ({
  type: BLOG_ACTION.TOGGLE_STATUS_REQUEST,
  payload: data,
});
export const toggletatusSuccess = ({ toggleStatusRes }) => ({
  type: BLOG_ACTION.TOGGLE_STATUS_SUCCESS,
  payload: { toggleStatusRes },
});
export const toggleStatusFailure = ({ toggleStatusRes }) => ({
  type: BLOG_ACTION.TOGGLE_STATUS_FAILURE,
  payload: { toggleStatusRes },
});
