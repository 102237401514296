import { ROUTE_API } from '../utils/route-util';
import HttpUtil from '../utils/http-util';

const advertise = {
  getAdList: async (data) =>
    await HttpUtil.get(ROUTE_API.getAdList, { params: data }),

  getAdDetail: async (id) =>
    await HttpUtil.get(ROUTE_API.getAdDetail.replace(':id', id)),

  createAd: async (formData) =>
    await HttpUtil.post(ROUTE_API.createAd, formData),

  updateAd: async (data) =>
    await HttpUtil.post(`${ROUTE_API.updateAd}/${data.id}`, data.formData),

  banOrRejectAd: async ({ id, data }) =>
    await HttpUtil.put(`${ROUTE_API.rejectAd}/${id}`, data),

  unbanAd: async (id) => await HttpUtil.put(`${ROUTE_API.unbanAd}/${id}`),

  getAdPackages: async () => await HttpUtil.get(ROUTE_API.getAdPackage),

  updateAdPackage: async ({ id, data }) =>
    await HttpUtil.put(ROUTE_API.updateAdPackage.replace(':id', id), data),
  
  updateAdStatus: async ({ id, status }) => await HttpUtil.post(ROUTE_API.updateAdStatus, { id, status }),
};

export default advertise;
