export const ADVERTISE_ACTION = {
  CREATE_AD_REQUEST: 'CREATE_AD_REQUEST',
  CREATE_AD_SUCCESS: 'CREATE_AD_SUCCESS',
  CREATE_AD_FAILURE: 'CREATE_AD_FAILURE',

  UPDATE_AD_REQUEST: 'UPDATE_AD_REQUEST',
  UPDATE_AD_SUCCESS: 'UPDATE_AD_SUCCESS',
  UPDATE_AD_FAILURE: 'UPDATE_AD_FAILURE',

  GET_AD_LIST_REQUEST: 'GET_AD_LIST_REQUEST',
  GET_AD_LIST_SUCCESS: 'GET_AD_LIST_SUCCESS',
  GET_AD_LIST_FAILURE: 'GET_AD_LIST_FAILURE',

  GET_AD_DETAIL_REQUEST: 'GET_AD_DETAIL_REQUEST',
  GET_AD_DETAIL_SUCCESS: 'GET_AD_DETAIL_SUCCESS',
  GET_AD_DETAIL_FAILURE: 'GET_AD_DETAIL_FAILURE',

  BAN_OR_REJECT_AD_REQUEST: 'BAN_OR_REJECT_AD_REQUEST',
  BAN_OR_REJECT_AD_SUCCESS: 'BAN_OR_REJECT_AD_SUCCESS',
  BAN_OR_REJECT_AD_FAILURE: 'BAN_OR_REJECT_AD_FAILURE',

  UNBAN_AD_REQUEST: 'UNBAN_AD_REQUEST',
  UNBAN_AD_SUCCESS: 'UNBAN_AD_SUCCESS',
  UNBAN_AD_FAILURE: 'UNBAN_AD_FAILURE',

  GET_AD_PACKAGE_REQUEST: 'GET_AD_PACKAGE_REQUEST',
  GET_AD_PACKAGE_SUCCESS: 'GET_AD_PACKAGE_SUCCESS',
  GET_AD_PACKAGE_FAILURE: 'GET_AD_PACKAGE_FAILURE',

  UPDATE_AD_PACKAGE_REQUEST: 'UPDATE_AD_PACKAGE_REQUEST',
  UPDATE_AD_PACKAGE_SUCCESS: 'UPDATE_AD_PACKAGE_SUCCESS',
  UPDATE_AD_PACKAGE_FAILURE: 'UPDATE_AD_PACKAGE_FAILURE',

  UPDATE_AD_STATUS_REQUEST: 'UPDATE_AD_STATUS_REQUEST',
  UPDATE_AD_STATUS_SUCCESS: 'UPDATE_AD_STATUS_SUCCESS',
  UPDATE_AD_STATUS_FAILURE: 'UPDATE_AD_STATUS_FAILURE'
};

export const createAdRequest = (data) => ({
  type: ADVERTISE_ACTION.CREATE_AD_REQUEST,
  payload: data,
});
export const createAdSuccess = ({ advertise }) => ({
  type: ADVERTISE_ACTION.CREATE_AD_SUCCESS,
  payload: { advertise },
});
export const createAdFailure = ({ advertise }) => ({
  type: ADVERTISE_ACTION.CREATE_AD_FAILURE,
  payload: { advertise },
});

export const updateAdRequest = (data) => ({
  type: ADVERTISE_ACTION.UPDATE_AD_REQUEST,
  payload: data,
});
export const updateAdSuccess = ({ updateAdRes }) => ({
  type: ADVERTISE_ACTION.UPDATE_AD_SUCCESS,
  payload: { updateAdRes },
});
export const updateAdFailure = ({ updateAdRes }) => ({
  type: ADVERTISE_ACTION.UPDATE_AD_FAILURE,
  payload: { updateAdRes },
});

export const getAdListRequest = (data) => ({
  type: ADVERTISE_ACTION.GET_AD_LIST_REQUEST,
  payload: data,
});
export const getAdListSuccess = ({ adList, totalPage, totalAds }) => ({
  type: ADVERTISE_ACTION.GET_AD_LIST_SUCCESS,
  payload: { adList, totalPage, totalAds },
});
export const getAdListFailure = () => ({
  type: ADVERTISE_ACTION.GET_AD_LIST_FAILURE,
});

export const getAdDetailRequest = (data) => ({
  type: ADVERTISE_ACTION.GET_AD_DETAIL_REQUEST,
  payload: data,
});
export const getAdDetailSuccess = ({ adDetail, totalSpent }) => ({
  type: ADVERTISE_ACTION.GET_AD_DETAIL_SUCCESS,
  payload: { adDetail, totalSpent },
});
export const getAdDetailFailure = () => ({
  type: ADVERTISE_ACTION.GET_AD_DETAIL_FAILURE,
});

export const banOrRejectAdRequest = (id, data) => ({
  type: ADVERTISE_ACTION.BAN_OR_REJECT_AD_REQUEST,
  payload: { id, data },
});
export const banOrRejectAdSuccess = ({ banOrRejectAdRes }) => ({
  type: ADVERTISE_ACTION.BAN_OR_REJECT_AD_SUCCESS,
  payload: { banOrRejectAdRes },
});
export const banOrRejectAdFailure = () => ({
  type: ADVERTISE_ACTION.BAN_OR_REJECT_AD_FAILURE,
});

export const unbanAdRequest = (id) => ({
  type: ADVERTISE_ACTION.UNBAN_AD_REQUEST,
  payload: id,
});
export const unbanAdSuccess = ({ unbanAdRes }) => ({
  type: ADVERTISE_ACTION.UNBAN_AD_SUCCESS,
  payload: { unbanAdRes },
});
export const unbanAdFailure = () => ({
  type: ADVERTISE_ACTION.UNBAN_AD_FAILURE,
});

export const getAdPackageRequest = () => ({
  type: ADVERTISE_ACTION.GET_AD_PACKAGE_REQUEST,
});
export const getAdPackageSuccess = ({ adPackages }) => ({
  type: ADVERTISE_ACTION.GET_AD_PACKAGE_SUCCESS,
  payload: { adPackages },
});
export const getAdPackageFailure = () => ({
  type: ADVERTISE_ACTION.GET_AD_PACKAGE_FAILURE,
});

export const updateAdPackageRequest = ({ id, data }) => ({
  type: ADVERTISE_ACTION.UPDATE_AD_PACKAGE_REQUEST,
  payload: { id, data },
});
export const updateAdPackageSuccess = ({ updateAdPackageRes }) => ({
  type: ADVERTISE_ACTION.UPDATE_AD_PACKAGE_SUCCESS,
  payload: { updateAdPackageRes },
});
export const updateAdPackageFailure = ({ updateAdPackageRes }) => ({
  type: ADVERTISE_ACTION.UPDATE_AD_PACKAGE_FAILURE,
  payload: { updateAdPackageRes },
});

export const updateAdStatusRequest = ({ id, status }) => ({
  type: ADVERTISE_ACTION.UPDATE_AD_STATUS_REQUEST,
  payload: { id, status }
});
export const updateAdStatusSuccess = ({ updateAdStatusRes }) => ({
  type: ADVERTISE_ACTION.UPDATE_AD_STATUS_SUCCESS,
  payload: { updateAdStatusRes }
});
export const updateAdStatusFailure = ({ updateAdStatusRes }) => ({
  type: ADVERTISE_ACTION.UPDATE_AD_STATUS_FAILURE,
  payload: { updateAdStatusRes }
});
